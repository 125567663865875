const swiper = new Swiper(".swiper", {
  loop: true,
  slidesPerView: "auto",
  speed: 8000,
  // allowTouchMove: false,
  autoplay: {
    delay: 0,
    disableOnInteraction: false,
  },
  breakpoints: {
    // スライドの表示枚数：500px以上の場合
    500: {
      loop: true,
      slidesPerView: "auto",
      speed: 20000,
      // allowTouchMove: false,
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
      },
    },
  },
});

// menuを開く/閉じる
const menu = document.querySelector(".l-nav__btn");
menu.addEventListener("click", () => {
  document.querySelector("html").classList.toggle("open");
});

// menuを閉じる
const menuItem = document.querySelectorAll(".js-menu");
menuItem.forEach((item) => {
  item.addEventListener("click", () => {
    document.querySelector("html").classList.remove("open");
  });
});

if ($(".loading").length) {
  const target = document.querySelector(".loading");
  window.addEventListener("load", () => {
    setTimeout(() => {
      target.classList.add("visible");
      document.querySelector("html").classList.remove("load");
    }, 3000);
  });
}

if ($(".js-section").length) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.from(".js-section", {});
  gsap.utils.toArray(".js-section").forEach((target) => {
    gsap
      .from(target, {
        y: 50,
        opacity: 0,
        scrollTrigger: {
          trigger: target,
          start: "top 70%",
        },
      })
      .timeScale(0.3);
  });
}

$(document).ready(function () {
  //アンカーリンク位置変更

  // ページのURLを取得
  const url = $(location).attr("href");
  // headerの高さを取得してそれに5px追加した値をheaderHeightに代入
  headerHeight = $("header").outerHeight() + 5;
  //$('a[href^="#"]').click(function () {
  // urlに「#」が含まれていれば
  if (url.indexOf("#") != -1) {
    // urlを#で分割して配列に格納
    const anchor = url.split("#");
    // 分割した最後の文字列（#◯◯の部分）をtargetに代入
    target = $("#" + anchor[anchor.length - 1]);
    // リンク先の位置からheaderHeightの高さを引いた値をpositionに代入
    position = Math.floor(target.offset().top) - headerHeight;
    // positionの位置に移動
    $("html, body").animate({ scrollTop: position }, 500);
  }
  //});

  //exValidation
  if ($(".validationform").length) {
  }
});
